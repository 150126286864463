<template>
  <div class="container">
    <nav class="level">
      <div class="level-item">
        <div>
          <div class="box">
            <section>
              <b-field>
                <template #label>
                {{ $t('calc.activePeriodText') }}
                <b-tooltip :label="$t('calc.activePeriodTextTip')" multilined>
                  <b-icon icon="information" type="is-light" size="is-small"></b-icon>
                </b-tooltip>
                </template>
                <b-slider
                  type="is-info"
                  size="is-large"
                  v-model="calcParams.ages"
                  :min="1"
                  :max="70"
                  :step="1"
                >
                  <template v-for="val in [1, 10, 20, 30, 40, 50, 60, 70]">
                    <b-slider-tick :value="val" :key="val">{{
                      val
                    }}</b-slider-tick>
                  </template>
                </b-slider>
              </b-field>
            </section>
          </div>
          <div class="box">
            <section id="profits">
              <p class="title is-6">{{ $t('calc.defineProfitsText') }}
                <b-tooltip :label="$t('calc.defineProfitsTextTip')" multilined>
                  <b-icon icon="information" type="is-light" size="is-small"></b-icon>
                </b-tooltip>
              </p>
              <b-field
                :label="$t('calc.labelActive')"
                label-position="on-border"
              >
                <b-slider
                  type="is-success"
                  size="is-large"
                  v-model="calcParams.anualActiveLifeProfit"
                  :min="1"
                  :max="25"
                >
                  <template v-for="val in [1, 5, 10, 15, 20, 25]">
                    <b-slider-tick :value="val" :key="val">{{
                      val
                    }}</b-slider-tick>
                  </template>
                </b-slider>
              </b-field>
              <b-field
                :label="$t('calc.labelRetirement')"
                label-position="on-border"
              >
                <b-slider
                  type="is-danger"
                  size="is-large"
                  v-model="calcParams.anualRetireProfit"
                  :min="1"
                  :max="25"
                >
                  <template v-for="val in [1, 5, 10, 15, 20, 25]">
                    <b-slider-tick :value="val" :key="val">{{
                      val
                    }}</b-slider-tick>
                  </template>
                </b-slider>
              </b-field>
              <b-field
                :label="$t('calc.labelInflation')"
                label-position="on-border"
              >
                <b-slider
                  type="is-warning"
                  size="is-large"
                  v-model="calcParams.anualInfationRate"
                  :min="0"
                  :max="10"
                  :step="0.5"
                >
                  <template v-for="val in [0, 2, 4, 6, 8, 10]">
                    <b-slider-tick :value="val" :key="val">{{
                      val
                    }}</b-slider-tick>
                  </template>
                </b-slider>
              </b-field>
            </section>
          </div>
        </div>
      </div>
      <!-- <br /> -->

      <div class="level-item">
        <div class="box">
          <section>
            <div class="label">
              <span class="has-text-centred">
                {{ $t('calc.currentStateText') }}
              </span>
            </div>
            <div class="has-text-left">
              <b-field
                :label="$t('calc.alreadySavedLabel')"
                :label-position="labelPosition"
              >
                <b-numberinput
                  v-model="calcParams.alreadySaved"
                  controls-alignment="right"
                  controls-position="compact"
                  :step="100"
                  :min-step="1"
                ></b-numberinput>
              </b-field>
              <b-field
                :label="$t('calc.canMonthlySaveLabel')"
                :label-position="labelPosition"
              >
                <b-numberinput
                  v-model="calcParams.monthlyPayments"
                  controls-alignment="right"
                  controls-position="compact"
                  :step="100"
                  :min-step="1"
                ></b-numberinput>
              </b-field>
            </div>
          </section>
        </div>
      </div>
      <div class="level-item">
        <div class="box">
          <section>
            <div class="label">
              <span class="has-text-centred">
                {{ $t('calc.assumptionText') }}
                <b-tooltip :label="$t('calc.assumptionTextTip')" multilined>
                  <b-icon icon="information" type="is-light" size="is-small"></b-icon>
                </b-tooltip>
              </span>
            </div>
            <b-field
              :label-position="labelPosition"
              :label="$t('calc.retirementIncomeLabel')"
            >
              <b-numberinput
                v-model="calcParams.retairmentPayments"
                controls-alignment="right"
                controls-position="compact"
                :step="100"
                :min-step="1"
              ></b-numberinput>
              <template slot="message">
                <div>
                  {{ $t('calc.retirementInflationIncomeText') }}
                  {{ calcParams.retairmentPaymentsFull }}
                </div>
              </template>
            </b-field>
          </section>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  data() {
    return {
      labelPosition: 'on-border'
    }
  },
  computed: mapGetters(['calcParams']),
  watch: {
    'calcParams.currentAge'() {
      this.recalcRetirePayments()
    },
    'calcParams.retairmentAge'() {
      this.recalcRetirePayments()
    },
    'calcParams.retairmentPayments'() {
      this.recalcRetirePayments()
    },
    'calcParams.anualInfationRate'() {
      this.recalcRetirePayments()
    },
    'calcParams.ages'() {
      this.changeCalcParameter({
        parameter: 'currentAge',
        value: this.calcParams.ages[0]
      })
      this.changeCalcParameter({
        parameter: 'retairmentAge',
        value: this.calcParams.ages[1]
      })
    }
  },
  methods: {
    ...mapMutations(['changeCalcParameter']),
    recalcRetirePayments() {
      let t =
        Number(this.calcParams.retairmentAge) -
        Number(this.calcParams.currentAge)
      let i =
        Number(this.calcParams.retairmentPayments) *
        Math.pow(1 + Number(this.calcParams.anualInfationRate) / 100, t)
      this.changeCalcParameter({
        parameter: 'retairmentPaymentsFull',
        value: Number(i.toFixed(1))
      })
    }
  },
  created() {
    this.recalcRetirePayments()
  }
}
</script>

<style scoped>
@import 'https://cdn.materialdesignicons.com/5.3.45/css/materialdesignicons.min.css';
.column {
  padding: 0.1rem;
}
input {
  width: 60px;
  text-align: right;
}
</style>
<style>
.b-slider .b-slider-thumb-wrapper .b-slider-thumb {
  height: 1rem;
  width: 1rem;
}
section#profits div.field div.b-slider {
  padding-bottom: 1rem;
}
section#profits div.field.is-floating-label label.label {
  top: -1.5em;
}
.field.is-floating-label.has-numberinput-compact .label, .field.is-floating-in-label.has-numberinput-compact .label {
  margin-left: calc(1rem * 0.25);
}
</style>
