<template>
  <div class="container">
    <div class="level">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">{{ $t('graph.accumulatedText') }}</p>
          <p class="title">{{ this.shortRetireSum }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">{{ $t('graph.profitsText') }}</p>
          <p class="title">{{ this.printedProfits }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">{{ $t('graph.runOutText') }}</p>
          <p class="title">{{ this.calcParams.zeroAt }}</p>
        </div>
      </div>
    </div>
    <line-chart
      v-if="loaded"
      :chartData="chartdata"
      :options="options"
      :style="chartStyles"
    />
  </div>
</template>

<script>
// import LineChart from './Chart.vue'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'LineChartContainer',
  components: {
    LineChart: () => import('./Chart.vue')
  },
  data: () => ({
    chartStyles: {
      height: '310px',
      width: '100%',
      position: 'relative'
    },
    chartdata: null,
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                if(parseInt(value) >= 1000){
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return value;
                }
              },
            }
          }
        ]
      },
      responsive: true,
      maintainAspectRatio: false
    },
    loaded: false
  }),
  watch: {
    calcParams: {
      deep: true,
      handler() {
        this.calcChartData()
      }
    },
    '$i18n.locale'() {
      this.calcChartData()
    }
  },
  computed: {
    ...mapGetters(['calcParams']),
    printedProfits() {
      let live = this.calcParams.anualActiveLifeProfit
      let retire = this.calcParams.anualRetireProfit
      let inflation = this.calcParams.anualInfationRate
      return `${live}% / ${retire}% / ${inflation}%`
    },
    years() {
      return 100 - this.calcParams.currentAge
    },
    activeYears() {
      return this.calcParams.retairmentAge - this.calcParams.currentAge
    },
    retireYears() {
      return 100 - this.calcParams.retairmentAge
    },
    whenRetire() {
      return Date().getFullYear() + this.activeYears
    },
    shortRetireSum() {
      // get from https://stackoverflow.com/a/40724354/1088547
      let number = this.calcParams.retireSum
      var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']
      // what tier? (determines SI symbol)
      var tier = (Math.log10(number) / 3) | 0

      // if zero, we don't need a suffix
      if (tier == 0) return number

      // get suffix and determine scale
      var suffix = SI_SYMBOL[tier]
      var scale = Math.pow(10, tier * 3)

      // scale the number
      var scaled = number / scale

      return scaled.toFixed(2) + suffix
    }
  },
  methods: {
    ...mapMutations(['changeCalcParameter']),
    calcChartData() {
      this.chartdata = {
        labels: [...Array(this.years).keys()].map(
          (i) => Number(this.calcParams.currentAge) + i
        ),
        datasets: [
          {
            label: this.$t('graph.accumulatingText'),
            data: this.calcAccumulating(),
            backgroundColor: ['rgba(0, 204, 0, 0.2)'],
            borderColor: ['rgba(0, 204, 0, 1)'],
            borderWidth: 1
          },
          {
            label: this.$t('graph.spendingText'),
            data: Array(this.activeYears)
              .fill(null)
              .concat(this.calcRetairment()),
            backgroundColor: ['rgba(255, 51, 0, 0.2)'],
            borderColor: ['rgba(255, 51, 0, 1)'],
            borderWidth: 1
          }
        ]
      }
    },
    calcAccumulating() {
      // get from https://money.stackexchange.com/a/26187

      var ir = Number(this.calcParams.anualInfationRate) / 100 // anual infation rate
      var p = Number(this.calcParams.alreadySaved) //  initial value
      var n = 12 // compounding periods per year
      var r = Number(this.calcParams.anualActiveLifeProfit / 100) - ir // nominal interest rate, compounded n times per year minus inflation rate
      var i = Number(r / n) // periodic interest rate
      // var i = Number((r / n).toFixed(8)) // periodic interest rate
      // var i = Number((Math.pow(1 + r, 1 / 12) - 1).toFixed(8))
      var y = 1 // this.activeYears // number of years
      var t = n * y // number of compounding periods
      var d = Number(this.calcParams.monthlyPayments) // periodic deposit

      var ret = [...Array(this.activeYears)].map((idx) => {
        let fv = this.calcFV(d, i, t)
        let pfv = this.calcPVF(p, i, t)
        p = fv + pfv
        // p = p - p * ir
        return Math.round(p * 100) / 100
      })
      ret.unshift(this.calcParams.alreadySaved)
      // this.calcParams.retireSum = Number(ret[ret.length - 1].toFixed(2))
      this.changeCalcParameter({
        parameter: 'retireSum',
        value: Number(ret[ret.length - 1].toFixed(2))
      })
      return ret
    },
    calcRetairment() {
      // get from https://money.stackexchange.com/a/26187

      var ir = Number(this.calcParams.anualInfationRate) / 100 // anual infation rate
      var p = Number(this.calcParams.retireSum) //  initial value
      var n = 12 // compounding periods per year
      var r = Number(this.calcParams.anualRetireProfit / 100) - ir // nominal interest rate, compounded n times per year minus inflation rate
      var i = Number(r / n) // periodic interest rate
      // var i = Number((r / n).toFixed(8)) // periodic interest rate
      // var i = Number((Math.pow(1 + r, 1 / 12) - 1).toFixed(8))
      var y = 1 // this.activeYears // number of years
      var t = n * y // number of compounding periods
      var d = -Number(this.calcParams.retairmentPaymentsFull) // periodic deposit

      var ret = Array()
      ret.push(p)
      while (p > 0 && ret.length < this.retireYears) {
        let fv = this.calcFV(d, i, t)
        let pfv = this.calcPVF(p, i, t)
        p = fv + pfv
        // p = p - p * ir
        if (p <= 0) {
          ret.push(0)
          break
        }
        ret.push(Math.round(p * 100) / 100)
      }
      if (ret[ret.length - 1] == 0) {
        this.changeCalcParameter({
          parameter: 'zeroAt',
          value: this.calcParams.retairmentAge + ret.length - 2
        })
      } else {
        this.changeCalcParameter({ parameter: 'zeroAt', value: '∞' })
      }
      return ret
    },
    calcFV(d, i, t) {
      if (i == 0) {
        return Number(d * t)
      }
      return Number((d * ((Math.pow(1 + i, t) - 1) / i) * (1 + i)).toFixed(2))
    },
    calcPVF(p, i, t) {
      if (i == 0) {
        return Number(p)
      }
      return Number((p * Math.pow(1 + i, t)).toFixed(2))
    }
  },
  created() {
    this.calcChartData()
  },
  async mounted() {
    this.loaded = true
  }
}
</script>

<style scoped>
.container {
  padding-top: 1rem;
}
</style>
