<template>
  <div class="container">
    <Inputs />
    <ChartContainer v-bind:calcParams="calcParams" />
  </div>
</template>

<script>
import Inputs from '@/components/Inputs'
import ChartContainer from '@/components/ChartContainer'
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['calcParams']),
  components: {
    Inputs,
    ChartContainer
  }
}
</script>
